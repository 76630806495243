<template>
  <div
    class="event d-flex flex-row align-items-center border-bottom py-3 px-1 px-md-3"
    :class="{ highlight: highlight }"
    :ref="shortDate(event.event_start_datetime, 'MM-DD')"
  >
    <div
      class="time-info d-flex flex-column align-items-start justify-content-start text-nowrap"
    >
      <div class="c-charcoal">
        {{ shortDate(event.event_start_datetime, 'MMM DD') }}
      </div>
      <div class="smaller">
        {{ shortTime(event.event_start_datetime) }}
      </div>
    </div>
    <div
      class="event-details-info d-flex flex-column align-items-start text-nowrap text-truncate ms-3 ms-md-4"
    >
      <EventNameComponent
        class="c-charcoal mw-100 text-truncate"
        :eventName="event.event_name"
        :isCancelled="event.is_cancelled"
      />
      <StudioEventTypeComponent
        :event="event"
        :displayRegistrations="displayRegistrations"
      />
    </div>
    <div
      class="instructors-info d-none d-md-flex justify-content-end flex-grow-1 ms-4"
    >
      <div class="d-flex flex-column align-items-center">
        <HMInstructorsStack
          class="min-width-70"
          :instructors="getSubstituteOrInitialIntructors"
          size="36px"
        />
        <HMTag v-if="numberOfSubstituteInstructors > 0" class="smallest mt-1">
          {{
            $t(
              'EVENTS.LABEL.SUBSTITUTE_VARIANT',
              numberOfSubstituteInstructors
            )
          }}
        </HMTag>
      </div>
    </div>
    <div
      class="instructors-info d-flex d-md-none justify-content-end flex-grow-1"
    >
      <div class="d-flex flex-column align-items-center">
        <HMInstructorsStack
          class="min-width-70"
          :instructors="getSubstituteOrInitialIntructors"
          size="28px"
        />
        <HMTag v-if="numberOfSubstituteInstructors > 0" class="smallest mt-1">
          {{
            $t(
              'EVENTS.LABEL.SUBSTITUTE_VARIANT',
              numberOfSubstituteInstructors
            )
          }}
        </HMTag>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import HMInstructorsStack from '@/components/common/HMInstructorsStack';
import * as EventUtils from '@/utils/events';
import StudioEventTypeComponent from '@/components/events/StudioEventTypeComponent';
import EventNameComponent from '@/components/events/EventNameComponent.vue';

export default defineComponent({
  name: 'CompactEvent',
  components: {
    HMInstructorsStack,
    StudioEventTypeComponent,
    EventNameComponent,
  },
  props: {
    event: {
      type: Object,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    displayRegistrations: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    eventInstructors() {
      return this.event.instructors;
    },
    eventSubstituteInstructors() {
      return this.event.substitute_instructors;
    },
    numberOfSubstituteInstructors() {
      return this.eventSubstituteInstructors?.length || 0;
    },
    numberOfInstructors() {
      return this.eventInstructors?.length || 0;
    },
    getSubstituteOrInitialIntructors() {
      if (this.numberOfSubstituteInstructors)
        return this.eventSubstituteInstructors;

      return this.eventInstructors;
    },
    getNumberOfSubstituteOrInitialIntructors() {
      return this.getSubstituteOrInitialIntructors?.length || 0;
    },
    hasInstructors() {
      return this.numberOfInstructors + this.numberOfSubstituteInstructors > 0;
    },
  },
  methods: {
    shortDate(date, format) {
      return EventUtils.shortDate(date, format);
    },
    shortTime(datetime) {
      return EventUtils.shortTime(datetime);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.event {
  height: 81px;
  font-size: min(4.5vw, 16px);

  &:hover {
    filter: brightness(90%);
    cursor: pointer;
  }

  &.highlight {
    animation: fade-background 3s ease-out;
  }

  @keyframes fade-background {
    0% {
      background-color: $coral;
    }
    100% {
      background-color: $white;
    }
  }
}
</style>
